import { FontsLoadedProvider } from "./Fonts"
import { ScreenProvider } from "./Screen"

interface ProvidersProps {
  children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <FontsLoadedProvider>
      <ScreenProvider>{children}</ScreenProvider>
    </FontsLoadedProvider>
  )
}
