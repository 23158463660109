import styled, { css } from 'styled-components'
import { fresponsive, fmobile } from 'library/fullyResponsive'
import gsap from 'gsap'
import { useRef } from 'react'
import UniversalLink from "library/Loader/UniversalLink"
import colors from 'styles/colors'
import text from 'styles/text'
import { ReactComponent as ArrowSVG } from 'images/global/arrow.svg'
import { pxToVw, vwToPx } from 'library/functions'
import { desktopDesignSize } from 'styles/media'
import getMedia from 'library/getMedia'

interface Props {
  label: string;
  link: string;
  to: string
  onClick?: VoidFunction
}

export default function MenuLink ({ label, link, to, onClick = undefined }: Props) {
  const circleRef = useRef<HTMLDivElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const mouseMove = (e: MouseEvent) => {    
    gsap.set(circleRef.current, {
      x: e.offsetX,
      y: e.offsetY,
      duration: 0.5
    })
  }

  const mouseEnter = () => {
    gsap.to(`#wrapper-${link}`, {
      color: colors.surface.white
    })

    gsap.to(circleRef.current, {
      width: () => getMedia(
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
      ),
      height: () => getMedia(
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
        vwToPx(pxToVw(115, desktopDesignSize) ?? 0),
      ),
      duration: 0.5,
      opacity: 1
    })

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('mousemove', mouseMove)
    }
  }

  const mouseLeave = () => {
    gsap.to(`#wrapper-${link}`, {
      color: colors.peach.true
    })

    gsap.to(circleRef.current, {
      width: 0,
      height: 0,
      duration: 0.5,
      opacity: 0
    })

    if (wrapperRef.current) {
      wrapperRef.current.removeEventListener('mousemove', mouseMove)
    }
  }

  return (
    <Wrapper onClick={onClick} ref={wrapperRef} id={`wrapper-${link}`} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <Label>{label}</Label>
      <Mask>
        <div id={`link-${link}`}>
          {onClick ? <LinkText>{link}</LinkText> : <Link to={to}>{link}</Link>}
        </div>
      </Mask>
      <Circle ref={circleRef}>
        <Arrow/>
      </Circle>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.peach.true};
  position: relative;
  cursor: none !important;

  ${fresponsive(css`
    gap: 10px;
  `)}

  ${fmobile(css`
    gap: 15px;
  `)}
`

const Label = styled.span`
  color: ${colors.surface.white};
  ${text.kicker1}
  text-transform: uppercase;
`

const Link = styled(UniversalLink)`
  ${text.h3}
  text-transform: uppercase;
  cursor: none !important;

  ${fmobile(css`
    ${text.h5}
  `)}
`

const LinkText = styled.span`
  ${text.h3}
  text-transform: uppercase;
  cursor: none !important;

  ${fmobile(css`
    ${text.h5}
  `)}
`

const Circle = styled.div`
  border-radius: 50%;
  position: absolute;
  background-color: ${colors.peach.true};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  transform: translate(-50%);
  cursor: none !important;
`

const Arrow = styled(ArrowSVG)`
  height: auto;

  path {
    stroke: ${colors.black.true};
  }

  ${fresponsive(css`
    width: 25px;
  `)}
`

const Mask = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
