import styled, { css, keyframes } from "styled-components"

import { fresponsive, ftablet, fmobile } from "library/fullyResponsive"
import colors from "styles/colors"
import text from 'styles/text'
import { ReactComponent as ArrowSVG } from 'images/global/arrow.svg'
import gsap from 'gsap'
import { useState, useEffect, useRef } from 'react'
import links from 'utils/links'
import MenuLink from "./MenuLink"
import Button from 'components/Button'
import { showModal } from "components/Venmo"
import { showModal as showPasswordModal } from "components/PasswordModal"
import UniversalLink from "library/Loader/UniversalLink"
import { loadPage } from "library/Loader/TransitionUtils"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { isBrowser } from "library/functions"

export default function Header() {

  const [open, setOpen] = useState(false)
  const [leftHeight, setLeftHeight] = useState(0)
  const [leftColors, setLeftColors] = useState<{
    borderColor: string,
    backgroundColor: string,
    color: string
  }>({
    borderColor: colors.black['700'],
    backgroundColor: colors.black['900'],
    color: colors.surface.white
  })
  const navRef = useRef<HTMLDivElement | null>(null)
  const line1Ref = useRef<SVGLineElement | null>(null)
  const line2Ref = useRef<SVGLineElement | null>(null)
  const line3Ref = useRef<SVGLineElement | null>(null)

  useEffect(() => {
    if (isBrowser()) {
      setLeftHeight(window.innerHeight)
    }
  }, [setLeftHeight])

  useEffect(() => {
    if (open) {
      ScrollSmoother.get()?.paused(true)

      const left = document.getElementById('header-left')
      const rect = left?.getBoundingClientRect()
      const style = left?.style
      setLeftHeight(rect?.height ?? window.innerHeight)
      setLeftColors({
        borderColor: style?.borderColor ?? leftColors.borderColor,
        backgroundColor: style?.backgroundColor ?? leftColors.backgroundColor,
        color: style?.color ?? leftColors.color
      })

      gsap.to(line1Ref.current, {
        attr: {
          x2: 15,
          y2: 15
        }
      })

      gsap.to("#header-left", {
        height: window.innerHeight,
        borderColor: colors.black['700'],
        backgroundColor: colors.black['900'],
        color: colors.surface.white
      })

      gsap.to(line2Ref.current, {
        opacity: 0
      })

      gsap.to(line3Ref.current, {
        attr: {
          x2: 15,
          y2: 0
        }
      })

      gsap.fromTo(navRef.current, {
        xPercent: -100
      }, {
        xPercent: 0,
      })

      gsap.fromTo('#link-rsvp', {
        yPercent: 120
      }, {
        yPercent: 20,
        delay: 0.2
      })
      gsap.fromTo('#link-registry', {
        yPercent: 120
      }, {
        yPercent: 20,
        delay: 0.4
      })
      gsap.fromTo('#link-details', {
        yPercent: 120
      }, {
        yPercent: 20,
        delay: 0.6
      })
    } else {
      ScrollSmoother.get()?.paused(false)

      gsap.to(navRef.current, {
        xPercent: -100
      })

      gsap.to('#header-left', {
        height: leftHeight || window.innerHeight,
        ...leftColors
      })

      gsap.to(line1Ref.current, {
        attr: {
          x2: 26,
          y2: 0.5
        }
      })

      gsap.to(line2Ref.current, {
        opacity: 1
      })

      gsap.to(line3Ref.current, {
        attr: {
          x2: 30,
          y2: 14.5
        }
      })
    }
  }, [open, setLeftHeight])
  
  const menuLinkClick = () => {
    // const password = localStorage.getItem('password')
    
    // if (password) {
      
    // } else {
    //   showPasswordModal()
    // }
    loadPage('/details', 'slide').catch((err: string) => {
      throw new Error(err)
    })
    setOpen(false)
  }

  return (
    <>
      <Wrapper>
        <Top onClick={() => setOpen(!open)}>
          <Menu viewBox="0 0 30 15">
            <Line ref={line1Ref} x1={0} x2={26} y1={0.5} y2={0.5} />
            <Line ref={line2Ref} x1={0} x2={20} y1={7.5} y2={7.5} />
            <Line ref={line3Ref} x1={0} x2={30} y1={14.5} y2={14.5} />
          </Menu>
        </Top>
        <Left id="header-left">
          <Marquee>
            <MarqueeInner>
              <span>We are saying I Do</span>&#9679;
              <span>We are saying I Do</span>&#9679;
              <span>We are saying I Do</span>&#9679;
              <span>We are saying I Do</span>&#9679;
              <span>We are saying I Do</span>&#9679;
              <span>We are saying I Do</span>&#9679;
            </MarqueeInner>
          </Marquee>
        </Left>
        <Right>
          <Date><UniversalLink to="/" transition="slide">zane & zoë</UniversalLink></Date>
          <Date><Arrow/><Arrow/>August 4, 2023</Date>
        </Right>
      </Wrapper>
      <Nav ref={navRef}>
        <MenuLink
          label="let us know you're joining us"
          link="rsvp"
          onClick={menuLinkClick}
        />
        <MenuLink
          label="places we are registered for gifts"
          link="registry"
          to={links.registry}
        />
        <MenuLink
          label="when and where it's happening"
          link="details"
          onClick={menuLinkClick}
        />
        <StyledButton onClick={showModal}>Help&nbsp;us&nbsp;honeymoon</StyledButton>
      </Nav>
    </>
  )
}

const Wrapper = styled.header`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: ${colors.black["900"]};
  border-bottom: 1px solid ${colors.black["700"]};
  width: 100%;
  display: flex;
  justify-content: flex-start;

  ${fresponsive(css`
    height: 53px;
  `)}
`

const Left = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  border-right: 1px solid ${colors.black['700']};
  opacity: 0.8;
  background-color: ${colors.black['900']};
  color: ${colors.surface.white};
  
  ${fresponsive(css`
    width: 80px;
  `)}

  ${fmobile(css`
    display: none;
  `)}
`

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  ${fresponsive(css`
    padding: 0px 15px;
  `)}

  ${ftablet(css`
    padding: 0px 30px;
  `)}

  ${fmobile(css`
    padding: 0px 10px;
  `)}
`

const Top = styled.div`
  border-right: 1px solid ${colors.black["700"]};
  border-bottom: 1px solid ${colors.black["700"]};
  background-color: ${colors.black["900"]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  ${fresponsive(css`
    height: 53px;
    width: 80px;
  `)}
`

const Menu = styled.svg`
  height: auto;
  cursor: pointer;

  ${fresponsive(css`
    width: 30px;
  `)}
`

const Line = styled.line`
  stroke: ${colors.surface.gray};
  stroke-width: 1px;
`

const Date = styled.div`
  color: ${colors.surface.white};
  display: flex;
  align-items: center;
  ${text.kicker1}

  ${fresponsive(css`
    gap: 10px;
  `)}
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  transform: rotate(180deg);

  path {
    stroke: ${colors.surface.white};
  }

  ${fresponsive(css`
    width: 10px;
  `)}
`

const marqueeEffect = keyframes`
  from {
    transform: translateX(-50%) rotate(180deg);
  }
  to {
    transform: translateX(0) rotate(180deg);
  }
`

const Marquee = styled.div`
  position: absolute;
  overflow: hidden;
  transform: rotate(90deg);
  transform-origin: bottom left;
  display: flex;
  align-items: center;

  ${fresponsive(css`
    width: 358px;
    height: 18px;
    top: 130px;
    left: 31px;
    gap: 14px;
    padding-left: 14px;
  `)}
`

const MarqueeInner = styled.div`
  animation: ${marqueeEffect} 10s linear infinite;
  display: flex;
  align-items: center;

  & > span {
    ${text.kicker2}
    width: fit-content;
    white-space: pre;
  }

  ${fresponsive(css`
    gap: 14px;
    padding-left: 14px;
  `)}
`

const Nav = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.black["900"]};
  z-index: 19;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${fresponsive(css`
    gap: 30px;
  `)}

  ${fmobile(css`
    gap: 90px;
    justify-content: flex-start;
    padding-top: 100px;
  `)}
`

const StyledButton = styled(Button)`
  position: absolute;
  background-color: transparent;
  border: 1px solid ${colors.peach.true};
  color: ${colors.peach.true};
  ${text.kicker1}
  cursor: pointer;

  svg {
    display: none;
  }
  
  ${fresponsive(css`
    left: 100px;
    bottom: 22px;
    height: 56px;
    gap: 0;
  `)}

  ${fmobile(css`
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
  `)}
`
