import styled, { css } from 'styled-components'
import { fresponsive } from 'library/fullyResponsive'
import colors from 'styles/colors'
import text from 'styles/text'
import UniversalLink from 'library/Loader/UniversalLink'
import { ReactNode, useEffect, useState, useRef } from 'react'
import { ReactComponent as ArrowSVG } from 'images/global/arrow.svg'
import gsap from 'gsap'

interface Props {
  children: ReactNode
  className?: string
  to: string
  onClick: VoidFunction,
  type?: string
}

export default function Button ({ children, className="", to, onClick, type=undefined }: Props) {

  const [timeline, setTimeline] = useState<GSAPTimeline | null>(null)
  const span1Ref = useRef<HTMLSpanElement | null>(null)
  const span2Ref = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
    })

    tl.to([span1Ref.current, span2Ref.current], {
      yPercent: -200,
      duration: 0.3,
      stagger: 0.1
    })

    tl.fromTo([span1Ref.current, span2Ref.current], {
      yPercent: 200
    }, {
      immediateRender: false,
      yPercent: 0,
      stagger: 0.1,
      duration: 0.3
    })

    setTimeline(tl)

    return () => {
      tl.kill()
    }
  }, [])

  const mouseEnter = () => {
    if (timeline) {
      timeline.play()
    }
  }

  const mouseLeave = () => {
    if (timeline) {
      timeline.reverse()
    }
  }

  return (
    <Wrapper transition="slide" className={className} to={to} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={onClick} type={type}>
      <span ref={span1Ref}>{children}</span>
      <span ref={span2Ref}><Arrow/></span>
    </Wrapper>
  )
}

const Wrapper = styled(UniversalLink)`
  width: fit-content;
  ${text.button}
  color: ${colors.black.true};
  background-color: ${colors.peach.true};
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${fresponsive(css`
    padding: 0px 30px;
    height: 90px;
    border-radius: 200px;
    gap: 15px;
  `)}
`

const Arrow = styled(ArrowSVG)`
  height: auto;

  ${fresponsive(css`
    width: 16.8px;
  `)}
`
