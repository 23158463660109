import styled, { css } from 'styled-components'
import { fresponsive, fmobile } from 'library/fullyResponsive'
import colors from 'styles/colors'
import text from 'styles/text'
import Button from 'components/Button'
import { ReactComponent as ArrowSVG } from 'images/global/arrow.svg'
import gsap from 'gsap'
import HandIMG from 'images/global/pointing.png'
import { useState } from 'react'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { useLocation } from '@reach/router'
import useMedia from 'library/useMedia'
import { loadPage } from 'library/Loader/TransitionUtils'

export const showModal = () => {
  const scroller = ScrollSmoother.get()
  scroller?.paused(true)
  gsap.set('#password-modal', {
    display: 'flex'
  })
  gsap.to('#password-modal', {
    opacity: 1
  })
}

export default function PasswordModal() {

  const buttonText = useMedia(
    "Submit Password",
    "Submit Password",
    "Submit Password",
    "Submit",
  )

  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const location = useLocation()

  const checkPassword = () => {
    if (password.toLowerCase() === "pookie") {
      localStorage.setItem('password', 'pookie')
      if (!location.pathname.includes('details')) {
        loadPage('/details', 'slide').catch((err: string) => {
          throw new Error(err)
        })
        hideModal(true)
      }
      hideModal(true)
    } else {
      setError(true)
    }
  }

  const hideModal = (pass: boolean) => {
    if (!location.pathname.includes('details') || pass) {
      const scroller = ScrollSmoother.get()
      scroller?.paused(false)
      gsap.to('#password-modal', {
        opacity: 0
      })
      gsap.set('#password-modal', {
        delay: 1,
        display: 'none'
      })
    }
  }

  return (
    <Wrapper id="password-modal">
      <Modal>
        <Top>
          <Kicker>Enter password to proceed<Arrow/><Arrow/></Kicker>
          <Close viewBox='0 0 22 22' onClick={() => hideModal(false)}>
            <Line x1={0} x2={22} y1={0} y2={22}/>
            <Line x1={0} x2={22} y1={22} y2={0}/>
          </Close>
        </Top>
        <Middle>
          <Text>
            We wish we could celebrate with all of you!
            However, due to the size of our venue,
            it is going to be an intimate ceremony.
            If you received an invitation,
            please enter the password provided.
          </Text>
          <Hand src={HandIMG} alt="pointer"/>
        </Middle>
        <ErrorLabel error={error}>Password is incorrect</ErrorLabel>
        <Input
          error={error}
          placeholder="Enter Password..."
          value={password}
          onChange={e => {
            setPassword(e.target.value)
            setError(false)
          }}
        />
        <StyledButton onClick={checkPassword}>{buttonText}</StyledButton>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  opacity: 0;
`

const Modal = styled.div`
  position: relative;
  background-color: ${colors.surface.beige};
  display: flex;
  flex-direction: column;

  ${fresponsive(css`
    height: 594px;
    width: 900px;
    padding: 122px 30px 40px;
  `)}
  
  ${fmobile(css`
    align-items: flex-start;
    width: 350px;
    height: 589px;
    padding-top: 68px;
  `)}
`

const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid ${colors.black['800']};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${fresponsive(css`
    height: 53px;
    padding: 0px 30px;
  `)}

  ${fmobile(css`
    justify-content: center;
    padding: 0;
  `)}
`

const Close = styled.svg`
  height: auto;
  cursor: pointer;

  ${fresponsive(css`
    width: 22px;
  `)}

  ${fmobile(css`
    position: relative;
    top: -60px;
    right: -20px;
  `)}
`

const Line = styled.line`
  stroke-width: 1px;
  stroke: ${colors.black.true};

  ${fmobile(css`
    stroke: ${colors.surface.white};
  `)}
`

const Kicker = styled.span`
  ${text.kicker1}
  color: ${colors.black.true};
  display: flex;
  align-items: center;

  ${fresponsive(css`
    gap: 10px;
  `)}
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  transform: rotate(90deg);
  
  path {
    stroke: ${colors.black.true};
  }

  ${fresponsive(css`
    width: 10px;
  `)}
`

const Middle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${fresponsive(css`
    margin-bottom: 26px;
  `)}

  ${fmobile(css`
    margin-bottom: 12px;
    width: 100%;
  `)}
`

const Text = styled.p`
  ${text.bodyL}
  color: ${colors.black.true};

  ${fresponsive(css`
    width: 531px;
  `)}
`

const Hand = styled.img`
  ${fresponsive(css`
    width: 270px;
    height: 145px;
  `)}

  ${fmobile(css`
    display: none;
  `)}
`

const Input = styled.input<{error: boolean}>`
  width: 100%;
  border: 1px solid ${props => props.error ? colors.peach.true : colors.black.true};
  ${text.bodyL}
  color: ${colors.black.true};

  ::placeholder {
    color: ${colors.surface.gray};
  }

  ${fresponsive(css`
    height: 92px;
    padding: 0px 40px;
    margin-bottom: 38px;
  `)}
`

const ErrorLabel = styled.span<{error: boolean}>`
  color: ${colors.peach.true};
  ${text.kicker1}
  opacity: ${props => props.error ? 1 : 0};
  transition: opacity 0.5s;

  ${fresponsive(css`
    margin-bottom: 11px;
  `)}
`

const StyledButton = styled(Button)`
  align-self: flex-end;

  ${fmobile(css`
    align-self: center;
    width: 309px;
    justify-content: center;
    height: 89px;
  `)}
`
