import styled, { css } from 'styled-components'
import { fresponsive, fmobile } from 'library/fullyResponsive'
import gsap from 'gsap'
import colors from 'styles/colors'
import text from 'styles/text'
import { ReactComponent as ArrowSVG } from 'images/global/arrow.svg'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

export const showModal = () => {
  gsap.set('#venmo', {
    display: 'flex'
  })
  gsap.to('#venmo', {
    opacity: 1
  })
}

const hideModal = () => {
  gsap.to('#venmo', {
    opacity: 0
  })
  gsap.set('#venmo', {
    delay: 1,
    display: 'none'
  })
}

export default function Venmo() {

  const images = useStaticQuery(graphql`
    query VennmoQuery {
      qr: file( relativePath: {eq: "global/venmo.jpg"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <Wrapper id="venmo">
      <Inner>
        <Top>
          <Kicker>Help fund our honeymoon<Arrow/><Arrow/></Kicker>
          <Close viewBox='0 0 22 22' onClick={hideModal}>
            <Line x1={0} x2={22} y1={0} y2={22}/>
            <Line x1={0} x2={22} y1={22} y2={0}/>
          </Close>
        </Top>
        <Text>
          We are thrilled you want to support our honeymoon fund!  You can pay us on Venmo with the Venmo QR Code or simply by username.
        </Text>
        {images?.qr?.childImageSharp?.gatsbyImageData && <QR image={images.qr.childImageSharp.gatsbyImageData} alt="venmo"/>}
        <Handle>@Zoe_Hoffman</Handle>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  opacity: 0;
`

const Inner = styled.div`
  position: relative;
  background-color: ${colors.surface.beige};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${fresponsive(css`
    width: 900px;
    height: 813px;
    padding-top: 95px;
  `)}

  ${fmobile(css`
    width: 350px;
    height: 579px;
    padding-top: 68px;
  `)}
`

const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid ${colors.black['800']};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${fresponsive(css`
    height: 53px;
    padding: 0px 30px;
  `)}

  ${fmobile(css`
    justify-content: center;
  `)}
`

const Close = styled.svg`
  height: auto;
  cursor: pointer;

  ${fresponsive(css`
    width: 22px;
  `)}

  ${fmobile(css`
    position: relative;
    top: -60px;
    right: -20px;
  `)}
`

const Line = styled.line`
  stroke-width: 1px;
  stroke: ${colors.black.true};

  ${fmobile(css`
    stroke: ${colors.surface.white};
  `)}
`

const Kicker = styled.span`
  ${text.kicker1}
  color: ${colors.black.true};
  display: flex;
  align-items: center;

  ${fresponsive(css`
    gap: 10px;
  `)}
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  transform: rotate(90deg);
  
  path {
    stroke: ${colors.black.true};
  }

  ${fresponsive(css`
    width: 10px;
  `)}
`

const Text = styled.p`
  ${text.bodyL};
  color: ${colors.black.true};

  ${fresponsive(css`
    width: 702px;
    margin-bottom: 40px;
  `)}

  ${fmobile(css`
    width: 306px;
    margin-bottom: 15px;
  `)}
`

const QR = styled(GatsbyImage)`
  ${fresponsive(css`
    width: 436px;
    height: 436px;
    margin-bottom: 34px;
  `)}

  ${fmobile(css`
    width: 282px;
    height: 282px;
    margin-bottom: 0;
  `)}
`

const Handle = styled.span`
  font-family: 'July 01';
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: ${colors.black.true};

  ${fresponsive(css`
    font-size: 50px;
    line-height: 60px;
  `)}

  ${fmobile(css`
    display: none;
  `)}
`