import { useEffect, useRef } from "react"

import gsap from "gsap"
import styled, { css } from "styled-components"

import {
  registerTransition,
  unregisterTransition,
} from "library/Loader/TransitionUtils"
import textStyles from "styles/text"
import colors from 'styles/colors'
import { ReactComponent as ZSVG } from 'images/global/Z.svg'
import { fresponsive } from "library/fullyResponsive"
import { ScrollSmoother } from "gsap/ScrollSmoother"

export default function Transition() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement | null>(null)

  const slideIn = () => {
    gsap.set(wrapperRef.current, {
      display: "flex"
    })
    gsap.set('.loader-mask', {
      scale: 1,
    })
    gsap.fromTo(wrapperRef.current, { yPercent: -100 },
      { yPercent: 0, duration: 1 })
  }

  useEffect(() => {
    const tl = gsap.timeline()

    tl.fromTo(innerRef.current, {
      xPercent: -56
    }, {
      xPercent: 0,
      duration: 1,
      repeat: -1,
      ease: "power2.inOut"
    })

    tl.to(innerRef.current, {
      xPercent: 0,
      duration: 1,
      ease: "power2.inOut"
    })    
  }, [])

  const slideOut = () => {
    ScrollSmoother.get()?.paused(false)

    const tl = gsap.timeline()

    tl.to('.loader-mask', {
      scale: 0,
    })

    tl.fromTo(wrapperRef.current, {
      yPercent: 0,
      opacity: 1,
    },
    {
      duration: 1,
      yPercent: -100,
      ease: "power1.out",
    })
  
  }

  useEffect(() => {
    // register a page transition
    registerTransition("slide", {
      in: slideIn,
      out: slideOut,
      inDuration: 1,
      outDuration: 1,
    })

    return () => {
      // clean up page transition
      unregisterTransition("slide", [slideIn, slideOut])
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Mask className="loader-mask">
        <Inner ref={innerRef}>
          <Z/>
          <Z/>
        </Inner>
      </Mask>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.surface.white};
  z-index: 100;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  ${textStyles.h1}
  display: none;
`

const Z = styled(ZSVG)`
  width: auto;
  height: 100%;

  path {
    fill: ${colors.black.true};
  }
`

const Mask = styled.div`
  overflow: hidden;
  position: relative;

  ${fresponsive(css`
    width: 50px;
    height: 75px;
  `)}
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;

  ${fresponsive(css`
    gap: 15px;
  `)}
`
