import { css } from "styled-components"

import { fresponsive } from "library/fullyResponsive"

const textStyles = {
  h1: fresponsive(css`
    font-family: "July 01";
    font-style: normal;
    font-weight: 400;
    font-size: 490px;
    line-height: 589px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
  h2: fresponsive(css`
    font-family: "July 01";
    font-style: normal;
    font-weight: 400;
    font-size: 400px;
    line-height: 481px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
  h3: fresponsive(css`
    font-family: "July 01";
    font-style: normal;
    font-weight: 400;
    font-size: 210px;
    line-height: 186px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
  h4: fresponsive(css`
    font-family: "July 01";
    font-style: normal;
    font-weight: 400;
    font-size: 150px;
    line-height: 140px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
  h5: fresponsive(css`
    font-family: "July 01";
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
  body: fresponsive(css`
    font-family: "Bradford";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  `),
  bodyL: fresponsive(css`
    font-family: 'Bradford';
    font-style: normal;
    font-weight: 300;
    font-size: 27px;
    line-height: 32px;
  `),
  button: fresponsive(css`
    font-family: "PP Mori";
    font-style: normal;
    font-weight: 600;
    font-size: 31px;
    line-height: 33px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  `),
  kicker1: fresponsive(css`
    font-family: "PP Mori";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    text-align: right;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
  kicker2: fresponsive(css`
    font-family: "Bradford";
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
  `),
  kicker3: fresponsive(css`
    font-family: 'PP Mori';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 26px;
    text-align: right;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  `),
}

export const strokeText = css`
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export const strokeTextTransparent = css`
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export const transparentText = css`
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
`

export default textStyles
