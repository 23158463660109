import styled from "styled-components"

import Header from "components/Header"
import Scroll from "library/Scroll"
import Preloader from 'components/Preloader'
import { useBackButton } from "library/Loader/TransitionUtils"
import { useTrackPageReady } from "library/pageReady"
import useCSSHeightVariables from "library/useCssHeightVariables"
import colors from 'styles/colors'
import Venmo from "components/Venmo"
import PasswordModal from "components/PasswordModal"
import Transition from "./Transition"

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  useTrackPageReady()
  useBackButton()
  useCSSHeightVariables()

  return (
    <>
      <Venmo/>
      <PasswordModal/>
      <Preloader/>
      <Transition/>
      <Header />
      <Scroll>
        <Main>{children}</Main>
      </Scroll>
    </>
  )
}

const Main = styled.main`
  background-color: ${colors.black['900']};
  overflow: hidden;
`
