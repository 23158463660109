import { createContext, useState, useEffect, ReactNode } from "react"

export const FontsLoadedContext = createContext<boolean>(false)

export function FontsLoadedProvider({ children }: { children: ReactNode }) {
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false)

  useEffect(() => {
    document.fonts.ready
      .then(() => {
        setFontsLoaded(true)
      })
      .catch(() => {
        console.error("uh oh fonts didnt load")
      })
  }, [setFontsLoaded])

  return (
    <FontsLoadedContext.Provider value={fontsLoaded}>
      {children}
    </FontsLoadedContext.Provider>
  )
}
