const colors = {
  black: {
    true: "#000000",
    900: "#0F0F11",
    800: "#2F2F33",
    700: "#3D3D40",
  },
  peach: {
    true: "#ED8E71",
    900: "#F0B7A5",
  },
  surface: {
    white: "#FFFFFF",
    gray: "#B6B6C6",
    beige: "#F0EEE7",
  },
} as const

export default colors
